import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";

function Navbar() {
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate("/profile");
	};

	const handleHomeClick = () => {
		navigate("/");
	};

	return (
		<nav className={styles.navbar}>
			<div className={styles.logo} onClick={handleHomeClick}>
				{/* Логотип или название приложения */}
				<h2>PhotoFinder</h2>
			</div>
			<div className={styles.menu}>
				<button onClick={handleProfileClick}>
					{/* Иконка профиля */}
					Мой профиль
				</button>
			</div>
		</nav>
	);
}

export default Navbar;
