// src/client/components/Navbar.js

import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css"; // Подключаем стили

function Navbar({ userData }) {
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate("/profile");
	};

	const handleHomeClick = () => {
		navigate("/");
	};

	return (
		<nav className={styles.navbar}>
			<div className={styles.navbarInner}>
				<div className={styles.navbarLogo} onClick={handleHomeClick}>
					<img src={`/images/logo.png`} />
				</div>
				<div className={styles.navbarMenu}>
					{userData?.first_name}
					<button onClick={handleProfileClick}></button>
				</div>
				<img
					className={styles.navbarTriangle}
					src={`/images/background-triangels.png`}
					alt="Динамическое изображение"
				/>
			</div>
		</nav>
	);
}

export default Navbar;
