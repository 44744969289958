// AdminLogin.js

import React, { useState } from "react";
import { useAdminAuth } from "../AdminAuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./AdminLogin.module.css";

function AdminLogin() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const { login } = useAdminAuth();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		login({ username, password });
		navigate("/admin");
	};

	return (
		<div className={styles.adminLogin}>
			<h2>Admin Login</h2>
			<form onSubmit={handleSubmit}>
				<label>
					Username:
					<input
						type="text"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						required
					/>
				</label>
				<label>
					Password:
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				</label>
				<button type="submit">Login</button>
			</form>
		</div>
	);
}

export default AdminLogin;
