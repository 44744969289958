import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styles from "./BookingPage.module.css";
import axiosInstance from "../../axiosConfig";
import Navbar from "../components/Navbar";
import Container from "../components/Container/Container";
import SelectionTimeSlot from "../components/SelectionTimeSlots/SelectionTimeSlots";

function BookingPage({ userData }) {
	const { id } = useParams(); // ID фотографа
	const [availableSlots, setAvailableSlots] = useState([]);
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedTime, setSelectedTime] = useState("");
	const [hasPastDates, setHasPastDates] = useState(false); // Состояние для проверки прошлых дат
	const location = useLocation();
	const navigate = useNavigate();
	const [photographer, setPhotographer] = useState(
		location.state?.photographer
	);
	const body = location.state;
	console.log(body);
	useEffect(() => {
		const fetchPhotographer = async () => {
			try {
				const response = await axiosInstance.get(
					`/photographers/${id}`
				);
				setPhotographer(response.data);
			} catch (error) {
				console.error("Ошибка при получении данных фотографа:", error);
			}
		};
		const checkAndDeletePastDates = async () => {
			try {
				// Получаем текущую дату без учета времени
				const currentDate = new Date();
				currentDate.setHours(0, 0, 0, 0);

				// Получаем расписание фотографа
				const response = await axiosInstance.get(
					`/photographers/${id}/schedule`
				);

				// Проверяем, есть ли даты меньше текущей
				const hasPastDates = response.data.schedule.some((slot) => {
					const slotDate = new Date(slot.date); // Преобразуем строку в дату
					slotDate.setHours(0, 0, 0, 0); // Убираем время
					return slotDate < currentDate; // Сравниваем только дату
				});

				if (hasPastDates) {
					setHasPastDates(true);
				} else {
					setHasPastDates(false);
				}
			} catch (error) {
				console.error(
					"Ошибка при проверке и удалении прошедших дат:",
					error
				);
			}
		};

		fetchPhotographer();
		checkAndDeletePastDates();
	}, [id]);

	const handleDateChange = async (e) => {
		setSelectedDate(e.target.value);
		try {
			const selectedDate = new Date(e.target.value);
			selectedDate.setHours(0, 0, 0, 0);

			// Получаем текущую дату без учета времени
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);

			// Проверяем, является ли дата прошедшей
			const isPastDate = selectedDate < currentDate;
			if (isPastDate) {
				setHasPastDates(true);
				return;
			} else {
				setHasPastDates(false);
			}

			// Получаем VIP-статус клиента
			const initDataUnsafe = await window.Telegram?.WebApp
				?.initDataUnsafe;
			const clientTelegramId = initDataUnsafe?.user?.id;

			const clientResponse = await axiosInstance.get(
				`/clients/telegram/${clientTelegramId}`
			);

			const isVip = await clientResponse.data.photographers.some(
				(photographer) => photographer.photographerId === id
			);
			if (isVip) {
				// Если клиент VIP, все слоты открыты
				setAvailableSlots(["Any"]); // Указываем "Any", чтобы клиент мог выбрать любое время
			} else {
				// Если клиент не VIP, запрашиваем расписание фотографа
				const response = await axiosInstance.get(
					`/photographers/${id}/schedule`,
					{
						params: { date: e.target.value },
					}
				);
				setAvailableSlots(response.data.availableSlots);
			}
		} catch (error) {
			console.error(
				"Ошибка при получении слотов или статуса VIP:",
				error
			);
		}
	};

	const handleBooking = async () => {
		try {
			const initDataUnsafe = window.Telegram?.WebApp?.initDataUnsafe;
			const clientTelegramId = initDataUnsafe?.user?.id;

			// Получаем данные клиента по telegramId
			const clientResponse = await axiosInstance.get(
				`/clients/telegram/${clientTelegramId}`
			);
			const clientId = clientResponse.data._id;
			// Проверяем, является ли клиент VIP
			const isVip = await clientResponse.data.photographers.some(
				(photographer) => photographer.photographerId === id
			);
			// Создаем данные бронирования
			const bookingData = {
				clientId,
				photographerId: photographer._id,
				date: selectedDate,
				timeSlot: selectedTime,
				isVip,
			};
			// Создаем бронирование
			await axiosInstance.post("/bookings", bookingData);

			if (isVip) {
				alert(
					"Бронирование успешно создано! Вам не нужно будет подтверждать оплату."
				);
			} else {
				alert(
					"Бронирование успешно создано! Перейдите в раздел 'Мои бронирования', чтобы подтвердить предоплату."
				);
			}

			navigate("/"); // Перенаправление на главную или другую страницу
		} catch (error) {
			console.error("Ошибка при создании бронирования:", error);
			alert(`Произошла ошибка при создании бронирования. ${error}`);
		}
	};

	// Если фотографа нет, показываем сообщение о загрузке
	if (!photographer) return <div>Загрузка...</div>;
	console.log(
		`/${photographer.profilePhoto.substring(
			photographer.profilePhoto.indexOf("images")
		)}`
	);
	return (
		<div className={styles.booking}>
			<Navbar userData={userData} />
			<Container>
				<div className={styles.bookingInner}>
					<div className={styles.imageBox}>
						<img
							src={`/${photographer.profilePhoto.substring(
								photographer.profilePhoto.indexOf("images")
							)}`}
						/>
					</div>
					<div className={styles.contentBox}>
						<div className={styles.adInfoBlock}>
							<h2 className={styles.title}>
								{photographer.firstName} {photographer.lastName}
							</h2>
							<div className={styles.adInfoInner}>
								<div className={styles.adInfoItem}>
									Возраст: {photographer.age}
								</div>
								<div className={styles.delimiter}></div>
								<div className={styles.adInfoItem}>
									Силь: {photographer.favoriteStyles[0]}
								</div>
							</div>
						</div>
						<div className={styles.adInfoBlock}>
							<h2 className={styles.title}>Опыт работы:</h2>
							<p className={styles.adInfoText}>
								Я занимаюсь фотографией уже более 4 лет и за это
								время прошёл путь от новичка до профессионала. Я
								начинал с портретной и уличной съёмки, но со
								временем открыл для себя множество других
								жанров: свадебную, репортажную, рекламную
								фотографию. Мой опыт включает работу как с
								частными клиентами, так и с корпоративными
								заказами.
							</p>
						</div>
					</div>
				</div>
				<div className={styles.selectionBlock}>
					<label>
						<h5>Выберите дату:</h5>
						<input
							type="date"
							value={selectedDate}
							onChange={handleDateChange}
						/>
					</label>

					{hasPastDates ? (
						<div>
							Вы выбрали прошедшую дату. Пожалуйста, выберите
							другую.
						</div>
					) : availableSlots.length > 0 ? (
						<>
							<SelectionTimeSlot
								availableSlots={availableSlots}
								setSelectedTime={setSelectedTime}
								selectedTime={selectedTime}
							/>
							<button
								className={styles.button}
								onClick={handleBooking}
							>
								Подтвердить бронирование
							</button>
						</>
					) : (
						selectedDate && (
							<p>
								Нет доступных временных слотов на выбранную
								дату.
							</p>
						)
					)}
				</div>
			</Container>
		</div>
	);
}

export default BookingPage;
