// Dashboard.js

import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import styles from "./Dashboard.module.css";

function Dashboard() {
	return (
		<div className={styles.adminDashboard}>
			<Sidebar />
			<div className={styles.dashboardContent}>
				<h2>Welcome to the Admin Dashboard</h2>
				{/* You can add dashboard widgets and stats here */}
			</div>
		</div>
	);
}

export default Dashboard;
