import React, { useState, useEffect } from "react";
import styles from "./SelectionTimeSlots.module.css";

// Функция для преобразования строкового времени в число для правильного сравнения
const timeToNumber = (time) => {
	const [hours, minutes] = time.split(":").map(Number);
	return hours * 60 + minutes;
};

// Функция для преобразования числа в строковое время
const numberToTime = (minutes) => {
	const hours = Math.floor(minutes / 60);
	const mins = minutes % 60;
	return `${hours}:${mins < 10 ? "0" + mins : mins}`;
};

function SelectionTimeSlot({ availableSlots, setSelectedTime, selectedTime }) {
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);

	// Функция обработки клика по слоту
	const handleSlotClick = (slot) => {
		const [slotStart, slotEnd] = slot.split("-").map((time) => time.trim());

		if (!startTime) {
			// Если начальное время не выбрано, выбираем начальное
			setStartTime(slot);
		} else if (startTime && !endTime) {
			// Если начальное выбрано, а конечное нет, выбираем конечное
			if (
				timeToNumber(slotStart) >
				timeToNumber(startTime.split("-")[0].trim())
			) {
				setEndTime(slot);
			} else {
				// Если выбранный слот раньше начального, то меняем начальное время
				setStartTime(slot);
				setEndTime(null); // сбрасываем конечное время
			}
		} else if (startTime && endTime) {
			// Если оба промежутка уже выбраны, меняем только один из них
			const startMinutes = timeToNumber(startTime.split("-")[0].trim());
			const endMinutes = timeToNumber(endTime.split("-")[1].trim());
			const selectedSlotStart = timeToNumber(slot.split("-")[0].trim());
			const selectedSlotEnd = timeToNumber(slot.split("-")[1].trim());

			if (selectedSlotStart < startMinutes) {
				// Если выбранный слот раньше начального, обновляем начальное время
				setStartTime(slot);
			} else if (selectedSlotEnd > endMinutes) {
				// Если выбранный слот позже конечного, обновляем конечное время
				setEndTime(slot);
			}
		}
	};

	// Функция для отмены выбора промежутка при повторном нажатии
	const handleActiveSlotClick = (slot) => {
		if (slot === startTime) {
			setStartTime(null);
		} else if (slot === endTime) {
			setEndTime(null);
		}
	};

	// Функция для вычисления итогового времени
	const getFinalTimeRange = () => {
		if (startTime && endTime) {
			const startMinutes = timeToNumber(startTime.split("-")[0].trim());
			const endMinutes = timeToNumber(endTime.split("-")[1].trim());

			const finalStart = numberToTime(Math.min(startMinutes, endMinutes));
			const finalEnd = numberToTime(Math.max(startMinutes, endMinutes));

			return `${finalStart} - ${finalEnd}`;
		} else if (startTime && !endTime) {
			return `${startTime.split("-")[0].trim()} - ${startTime
				.split("-")[1]
				.trim()}`;
		} else if (!startTime && endTime) {
			return `${endTime.split("-")[0].trim()} - ${endTime
				.split("-")[1]
				.trim()}`;
		}
		return "";
	};

	// Получаем активные промежутки
	const getActiveSlots = () => {
		let activeSlots = [];
		if (startTime && endTime) {
			const startMinutes = timeToNumber(startTime.split("-")[0].trim());
			const endMinutes = timeToNumber(endTime.split("-")[1].trim());

			activeSlots = availableSlots.filter((slot) => {
				const slotStart = timeToNumber(slot.split("-")[0].trim());
				const slotEnd = timeToNumber(slot.split("-")[1].trim());
				return slotStart >= startMinutes && slotEnd <= endMinutes;
			});
		} else if (startTime) {
			activeSlots = [startTime];
		} else if (endTime) {
			activeSlots = [endTime];
		}
		return activeSlots;
	};

	// Сортируем доступные слоты перед рендерингом
	const sortedAvailableSlots = availableSlots.sort((a, b) => {
		const aStart = timeToNumber(a.split("-")[0].trim());
		const bStart = timeToNumber(b.split("-")[0].trim());
		return aStart - bStart;
	});

	const activeSlots = getActiveSlots().sort((a, b) => {
		const aStart = timeToNumber(a.split("-")[0].trim());
		const bStart = timeToNumber(b.split("-")[0].trim());
		return aStart - bStart;
	});

	// Вызываем setSelectedTime для обновления состояния с финальным промежутком времени
	React.useEffect(() => {
		const finalTimeRange = getFinalTimeRange();
		if (finalTimeRange) {
			setSelectedTime(finalTimeRange);
		}
	}, [startTime, endTime, setSelectedTime]); // Зависит от startTime и endTime

	return (
		<div className={styles.selectionTimeSlots}>
			<h5>Выберите время:</h5>
			<div className={styles.selectionTimeSlotInner}>
				{sortedAvailableSlots.map((slot, index) => (
					<div
						key={index}
						className={`${styles.timeSlot} ${
							activeSlots.includes(slot) ||
							slot === startTime ||
							slot === endTime
								? `${styles.active}`
								: ``
						}`}
						onClick={() => {
							handleSlotClick(slot);
							handleActiveSlotClick(slot); // Отменяем выбор, если это уже выбранный слот
						}}
					>
						{slot}
					</div>
				))}
			</div>
			{getFinalTimeRange() ? (
				<div className="selectedTime">
					Выбранный промежуток: {getFinalTimeRange()}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

export default SelectionTimeSlot;
