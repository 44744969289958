// UsersList.js

import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
} from "@mui/material";
import styles from "./UsersList.module.css";

function UsersList() {
	const [users, setUsers] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [photographerData, setPhotographerData] = useState({
		lastName: "",
		age: "",
		experience: "",
		favoriteStyles: "",
		hourlyRate: "",
		sessionTypes: "",
		status: "",
	});
	const [profilePhoto, setProfilePhoto] = useState(null);

	useEffect(() => {
		// Получаем список пользователей с сервера
		axiosInstance
			.get("/clients")
			.then((response) => setUsers(response.data))
			.catch((error) =>
				console.error("Ошибка при получении пользователей:", error)
			);
	}, []);

	const handleOpenDialog = (user) => {
		setSelectedUser(user);
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
		setSelectedUser(null);
		setPhotographerData({
			lastName: "",
			age: "",
			experience: "",
			favoriteStyles: "",
			hourlyRate: "",
			sessionTypes: "",
			status: "",
		});
		setProfilePhoto(null);
	};

	const handleInputChange = (e) => {
		console.log(e.target.name, e.target.value);
		setPhotographerData({
			...photographerData,
			[e.target.name]: e.target.value,
		});
	};

	const handleFileChange = (e) => {
		setProfilePhoto(e.target.files[0]);
	};

	const handlePromote = async () => {
		try {
			const formData = new FormData();
			formData.append("lastName", photographerData.lastName);
			formData.append("age", photographerData.age);
			formData.append("experience", photographerData.experience);
			formData.append("favoriteStyles", photographerData.favoriteStyles);
			formData.append("hourlyRate", photographerData.hourlyRate);
			formData.append("sessionTypes", photographerData.sessionTypes);
			formData.append("status", photographerData.status);
			formData.append("profilePhoto", profilePhoto);
			formData.append("type", "profile");

			formData.forEach((item) => {
				console.log(item);
			});
			const response = await axiosInstance.post(
				`/clients/${selectedUser._id}/promote?type=profile`,
				formData
			);

			alert("Пользователь успешно повышен до фотографа!");
			setUsers((prevUsers) =>
				prevUsers.map((user) =>
					user._id === selectedUser._id
						? { ...user, isPhotographer: true }
						: user
				)
			);

			handleCloseDialog();
		} catch (error) {
			console.error("Ошибка при повышении пользователя:", error);
			alert("Не удалось повысить пользователя");
		}
	};

	return (
		<div className={styles.adminUserList}>
			<Sidebar />
			<div className={styles.usersContent}>
				<Navbar />
				<h2>Управление пользователями</h2>
				<table>
					<thead>
						<tr>
							<th>Имя</th>
							<th>Telegram ID</th>
							<th>Email</th>
							<th>Действия</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user._id}>
								<td>{user.name}</td>
								<td>{user.telegramId}</td>
								<td>{user.email || "N/A"}</td>
								<td>
									<button
										onClick={() => handleOpenDialog(user)}
										disabled={user.isPhotographer}
									>
										Promote
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{/* Диалоговое окно для ввода данных фотографа */}
				<Dialog open={open} onClose={handleCloseDialog}>
					<DialogTitle>Promote User to Photographer</DialogTitle>
					<DialogContent>
						<TextField
							label="Last Name"
							name="lastName"
							value={photographerData.lastName}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Age"
							name="age"
							value={photographerData.age}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Experience (years)"
							name="experience"
							value={photographerData.experience}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Favorite Styles"
							name="favoriteStyles"
							value={photographerData.favoriteStyles}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Hourly Rate"
							name="hourlyRate"
							value={photographerData.hourlyRate}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Session Types"
							name="sessionTypes"
							value={photographerData.sessionTypes}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Status"
							name="status"
							value={photographerData.status}
							onChange={handleInputChange}
							fullWidth
							margin="normal"
						/>
						<input
							type="file"
							accept="image/*"
							onChange={handleFileChange}
							style={{ marginTop: "16px" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog} color="secondary">
							Cancel
						</Button>
						<Button onClick={handlePromote} color="primary">
							Promote
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
}

export default UsersList;
