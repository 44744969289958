// Sidebar.js

import React from "react";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.css";

function Sidebar() {
	return (
		<div className={styles.adminSidebar}>
			<h2>Admin Panel</h2>
			<nav>
				<ul>
					<li>
						<Link to="/admin/">Dashboard</Link>
					</li>
					<li>
						<Link to="/admin/users">Manage Users</Link>
					</li>
					<li>
						<Link to="/admin/photographers">
							Manage Photographers
						</Link>
					</li>
					<li>
						<Link to="/admin/bookings">Manage Bookings</Link>
					</li>
					{/* Add more navigation links as needed */}
				</ul>
			</nav>
		</div>
	);
}

export default Sidebar;
