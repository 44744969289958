import React, { useState, useEffect } from "react";
import PhotographerCard from "../components/PhotographerCard";
import Navbar from "../components/Navbar";
import axiosInstance from "./../../axiosConfig";
import Container from "../components/Container/Container";
import stylizing from "./SearchPage.module.css";

function SearchPage({ userData }) {
	const [photographers, setPhotographers] = useState([]);
	const [search, setSearch] = useState("");
	const [level, setLevel] = useState("");
	const [age, setAge] = useState("");
	const [styles, setStyles] = useState("");
	const [experience, setExperience] = useState("");
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(false);

	// Фетч фотографов, проверяющий наличие userData
	const fetchPhotographers = async () => {
		if (!userData?.id) return; // Ждём, пока userData будет доступен
		setLoading(true);
		try {
			const response = await axiosInstance.get("/photographers", {
				params: {
					search,
					level,
					age,
					styles,
					experience,
					clientTelegramId: userData.id,
					page,
					limit: 10,
				},
			});
			setPhotographers(response.data.photographers);
			setTotalPages(response.data.pages);
		} catch (error) {
			console.error("Ошибка при получении фотографов:", error);
		} finally {
			setLoading(false);
		}
	};

	// Выполняем fetch при изменении фильтров и userData
	useEffect(() => {
		if (userData?.id) {
			fetchPhotographers();
		}
	}, [userData, search, level, age, styles, experience, page]);

	// Если userData ещё не загружен, показываем сообщение о загрузке
	if (!userData) {
		return <p>Загрузка данных пользователя...</p>;
	}

	return (
		<div className={stylizing.searchPage}>
			<Navbar userData={userData} />
			<Container>
				<h1 className={stylizing.blockTitle}>Поиск фотографов</h1>
				{/* Форма поиска и фильтров */}
				<div className={stylizing.searchFilters}>
					<div className={stylizing.inputBox}>
						<input
							type="text"
							placeholder="Поиск по имени"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className={stylizing.inputBox}>
						<input
							type="number"
							placeholder="Мин. возраст"
							value={age}
							onChange={(e) => {
								setAge(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className={stylizing.inputBox}>
						<input
							type="text"
							placeholder="Стили (через запятую)"
							value={styles}
							onChange={(e) => {
								setStyles(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className={stylizing.inputBox}>
						<input
							type="number"
							placeholder="Опыт (лет)"
							value={experience}
							onChange={(e) => {
								setExperience(e.target.value);
								setPage(1);
							}}
						/>
					</div>
					<div className={stylizing.selectBox}>
						<select
							value={level}
							onChange={(e) => {
								setLevel(e.target.value);
								setPage(1);
							}}
						>
							<option value="">Все уровни</option>
							<option value="novice">Новичок</option>
							<option value="intermediate">Профессионал</option>
							<option value="pro">Эксперт</option>
						</select>
					</div>
				</div>
			</Container>
			{loading ? (
				<Container>
					<p>Загрузка...</p>
				</Container>
			) : (
				<Container>
					<>
						{photographers.length > 0 ? (
							<div className={stylizing.photographersList}>
								{photographers.map((photographer) => (
									<PhotographerCard
										key={photographer._id}
										photographer={photographer}
									/>
								))}
							</div>
						) : (
							<p>Фотографы не найдены</p>
						)}
						{/* <div className={stylizing.pagination}>
							<button
								onClick={() => setPage(page - 1)}
								disabled={page === 1}
							>
								Предыдущая
							</button>
							<span>
								Страница {page} из {totalPages}
							</span>
							<button
								onClick={() => setPage(page + 1)}
								disabled={page === totalPages}
							>
								Следующая
							</button>
						</div> */}
					</>
				</Container>
			)}
		</div>
	);
}

export default SearchPage;
