// PhotographerCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PhotographerCard.module.css";

function PhotographerCard({ photographer }) {
	const navigate = useNavigate();

	const handleBookClick = () => {
		navigate(`/photographer/${photographer._id}/book`, {
			state: { photographer },
		});
	};

	return (
		<div className={styles.photographerCard}>
			<div className={styles.photographerImage}>
				<img
					src={`/${photographer.profilePhoto.substring(
						photographer.profilePhoto.indexOf("images")
					)}`}
					alt={`${photographer.firstName} ${photographer.lastName}`}
					className={styles.photographerPhoto}
				/>
			</div>
			<div className={styles.photographerInfo}>
				<h2 className={styles.cardTitle}>
					{photographer.firstName} {photographer.lastName}
				</h2>
				<div>
					<p>Возраст: {photographer.age}</p>
					<p>Опыт: {photographer.experience} лет</p>
					<p>
						Любимые стили: {photographer.favoriteStyles.join(", ")}
					</p>
				</div>
				<button className={styles.button} onClick={handleBookClick}>
					Забронировать
				</button>
			</div>
			<img
				className={styles.backgroundTriangle}
				src={`/images/background-triangels.png`}
				alt="Динамическое изображение"
			/>
		</div>
	);
}

export default PhotographerCard;
